import type {CSSProperties, ReactElement} from 'react';
import React from 'react';
import type {USER_AUDIO_SOURCE, UserAudioVOResponse} from '@Libraries/user-audio-library';
import {escapeHTML} from '@Utils/string.util';
import {updateGridItem} from '@Components/base-grid';
import AudioItem from '../audio-item';
import type { GridItemProps} from '../grid-item';
import {GRID_ITEM_TYPE} from '../grid-item';
import type { GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';
import {useAppDispatch} from '@/hooks';

export interface GridUserAudioItemStorage extends GridMediaItemStorage {
  type: GRID_ITEM_TYPE.USER_AUDIO;
  hashedFilename: string;
  uploaderName: string;
  name: string;
  uploaderId: number;
  duration: number;
  source: USER_AUDIO_SOURCE;
  gridItemWidth?: number;
  isHidden?: boolean;
}

interface GridUserAudioItemProps extends GridItemProps {
  hashedFilename: string;
  audioSrc: string;
  imgthumbSrc: string;
  name: string;
  uploaderName: string;
  duration: number;
  gridItemWidth?: number;
  isHidden?: boolean;
}

export const getGridUserAudioItemStorageForUserAudioVO = (userAudioVO: UserAudioVOResponse): GridUserAudioItemStorage => {
  return {
    type: GRID_ITEM_TYPE.USER_AUDIO,
    id: userAudioVO.filename,
    hashedFilename: userAudioVO.filename,
    uploaderName: userAudioVO.uploaderName,
    uploaderId: parseInt(userAudioVO.uploaderId, 10),
    source: userAudioVO.src,
    name: userAudioVO.name,
    duration: userAudioVO.duration,
    isHidden: false,
  };
};

export function GridUserAudioItem({uploaderName = '', ...props}: GridUserAudioItemProps): React.ReactElement | null {
  const dispatch = useAppDispatch();

  const onItemClicked = (e: React.MouseEvent, isDoubleClick: boolean): void => {
    if ($(e.target).hasClass('rename-audio-option') || $(e.target).parents().hasClass('rename-audio-option')) {
      return;
    }
    if (props.onClicked) {
      props.onClicked(e, isDoubleClick);
    }
  };

  const onNameChanged = async (newName: string): Promise<void> => {
    await window.PMW.writeLocal('posteraudio/updateName', {
      name: newName,
      hash: props.hashedFilename,
    });
    dispatch(
      updateGridItem({
        gridId: props.gridId ?? '',
        itemId: props.id,
        changes: {
          name: newName,
        },
      })
    );
  };

  if (props.isHidden) {
    return null;
  }

  const inlineStyles: CSSProperties = props.style ?? {};
  if (props.gridItemWidth) {
    inlineStyles.width = props.gridItemWidth;
  }

  const getAudioThumb = (): ReactElement => {
    return (
      <AudioItem
        uid={props.id}
        title={props.name}
        duration={props.duration}
        source={props.audioSrc}
        showThreeDots
        onTitleRenamed={async (newTitle: string): Promise<void> => {
          await onNameChanged(newTitle);
        }}
      />
    );
  };

  return (
    <GridMediaItem
      {...props}
      onClicked={onItemClicked}
      style={inlineStyles}
      className={`${props.className ?? ''}`}
      title={uploaderName ? `${window.i18next.t('pmwjs_added_by')} ${escapeHTML(uploaderName)}` : ''}
      paddedThumb={false}
      thumb={getAudioThumb()}
    />
  );
}
