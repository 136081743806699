import type {CSSProperties, MouseEvent, ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {Text, TextSize} from '@Components/text';
import {getRandomNumberInRange} from '@Utils/math.util';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {PMW_COLORS_NEUTRAL} from '@Utils/color.util';
import {InputField} from '@Components/input-field-v2';
import type {InputFieldControlItemEvent, InputFieldControls} from '@Components/input-field-v2/input-field.types';
import {InputControlItemType, InputFieldSize} from '@Components/input-field-v2/input-field.types';
import {v4 as uuidv4} from 'uuid';
import {Dropdown} from '@Components/dropdown-v2';
import {ALIGNMENT_TYPE, DROPDOWN_POSITION} from '@Components/dropdown-v2/dropdown.types';
import {ControlledList} from '@Components/controlled-list';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {getUniqueString} from '@Utils/string.util';
import {pauseAudioGridItem, playAudioGridItem} from '@Components/base-grid/components/grid-user-audio-item/grid-audio-item-reducer';
import useWindowSize from '@Hooks/useWindowSize';
import {openAudioItemRenameModal} from '@Modals/audio-item-rename-modal';
import {isTouchDevice} from '@Utils/browser.util';
import AudioItemWave from '../audio-item-wave/audio-item-wave';
import styles from './audio-item.module.scss';
import {useAppDispatch, useAppSelector} from '@/hooks';

const SMALL_SCREEN_WIDTH = 560;

export interface AudioItemProps {
  uid: string;
  title: string;
  duration: number;
  width?: number;
  height?: number;
  source: string;
  onTitleRenamed?: (newTitle: string) => Promise<void>;
  showThreeDots?: boolean;
}

export default function AudioItem({showThreeDots = false, ...props}: AudioItemProps): ReactElement {
  const audioGridItemPlaying = useAppSelector((state) => {
    return state.audioGridItem.audioGridItemPlaying;
  });
  const textContainerRef = useRef<HTMLSpanElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  const isPlaying = audioGridItemPlaying === props.uid;
  const [isRenaming, setIsRenaming] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [seekTime, setSeekTime] = useState(0);
  const editedtitle = useRef(props.title);
  const audioGraphicBackdropURL = useRef(`https://s3.amazonaws.com/dev.assets.postermywall.com/assets/audioitems/audio-${getRandomNumberInRange(1, 7)}.webp`);
  const id = useRef(uuidv4());
  const audioRef = useRef<HTMLAudioElement>(null);
  const renamingCloseBtnID = `rename-close-${id.current}`;
  const renamingDoneBtnID = `rename-done-${id.current}`;
  const {windowWidth} = useWindowSize();
  const dispatch = useAppDispatch();

  const isAudioNameLarge = (): boolean => {
    if (!textContainerRef.current) {
      return false;
    }
    return textContainerRef.current.scrollWidth > textContainerRef.current.offsetWidth;
  };

  const getInfoContainerClasses = (): string => {
    return `${styles.audioItemInfoContainer} flex-v-row flex-justify-between spacing-p-t-1 spacing-p-b-3 spacing-p-l-3 ${showThreeDots ? 'spacing-p-r-1' : 'spacing-p-r-3'}`;
  };

  const getAudioNameClasses = (): string => {
    return `${styles.audioTitleContainer} content-body ${isHovered && isAudioNameLarge() ? styles.audioTitleContainerScrolling : '_full-width'}`;
  };

  const getInfoContainer = (): ReactElement => {
    return (
      <div className={getInfoContainerClasses()}>
        <div className={`${styles.threeDotContainer} flex-justify-between flex-items-center`}>
          <Text ref={textContainerRef} val={title} size={TextSize.XXSMALL} className={getAudioNameClasses()} />
          {showThreeDots ? moreOptionsDropdown() : null}
        </div>
        <Text val={formatDuration(isPlaying ? seekTime : props.duration)} size={TextSize.XXSMALL} className={`${styles.audioItemDuration}`} />
      </div>
    );
  };

  const getAudioItemGraphicContainer = (): ReactElement => {
    return (
      <div className={`${styles.audioItemGraphicContainer} flex-center _full-height`}>
        <img loading="lazy" className={`${styles.audioItemGraphic} _full-width _full-height`} src={audioGraphicBackdropURL.current} alt="" />
        {isHovered || isSmallScreen() ? getAudioControlsOverlay() : getWaveOverlay()}
      </div>
    );
  };

  const openRenameModal = (): void => {
    openAudioItemRenameModal({
      audioName: title,
      renameAudio: async (name: string) => {
        onRenameFieldChanged(name);
        if (props.onTitleRenamed) {
          await props.onTitleRenamed(name);
        }
      },
    });
  };

  const moreOptionsDropdown = (): ReactElement => {
    if (windowWidth < SMALL_SCREEN_WIDTH) {
      return <Icon icon="icon-menu-dots" className="rename-audio-option" size={IconSize.SIZE_ICON_16} type={IconType.GHOST} shape={IconShape.SQUARE} onClick={openRenameModal} />;
    }
    return (
      <Dropdown
        id={props.uid}
        className={`rename-audio-option ${areOptionsOpen || isHovered || isSmallScreen() ? styles.showDropdownIcon : styles.hideDropdownIcon}`}
        popupSpacingClass="spacing-p-t-2 spacing-p-l-2 spacing-p-r-2"
        alignment={ALIGNMENT_TYPE.BOTTOM_START}
        selector={<Icon icon="icon-menu-dots" size={IconSize.SIZE_ICON_16} type={IconType.GHOST} shape={IconShape.SQUARE} />}
        popup={getDropdownPopup()}
        position={DROPDOWN_POSITION.FIXED}
        popUpHasCustomWidth
        onOpen={(): void => {
          setAreOptionsOpen(true);
        }}
        onClose={(): void => {
          setAreOptionsOpen(false);
        }}
      />
    );
  };

  const getDropdownPopup = (): ReactElement => {
    return (
      <div className={styles.popup}>
        <ControlledList
          className="_unmargin"
          items={[
            {
              id: `${getUniqueString()}`,
              type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
              icon: 'icon-pencil',
              text: window.i18next.t('pmwjs_rename'),
              onClick: onRenameClicked,
            },
          ]}
        />
      </div>
    );
  };

  const onRenameClicked = (_: string, e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    setIsRenaming(true);
  };

  const isSmallScreen = (): boolean => {
    return windowWidth < SMALL_SCREEN_WIDTH || isTouchDevice();
  };

  const getStyleObj = (): CSSProperties => {
    const styleObj: CSSProperties = {};
    styleObj.width = props.width ? `${props.width}px` : '100%';
    styleObj.height = props.height ? `${props.height}px` : '100%';
    return styleObj;
  };

  const getWaveOverlay = (): ReactElement => {
    return <AudioItemWave className={styles.waveOverlay} isAnimating={shouldAnimateAudioWave()} width={36} height={16} color={PMW_COLORS_NEUTRAL.NEUTRAL_0} />;
  };

  const shouldAnimateAudioWave = (): boolean => {
    return !isHovered && isPlaying;
  };

  const getAudioControlsOverlay = (): ReactElement => {
    return (
      <div className={`${styles.audioControlsOverlay} flex-center _full-width _full-height`}>
        <div className={`${styles.audioControlsHoverBackdrop} flex-center _full-height _full-width`} />
        <Icon
          onClick={shouldDisplayPlayBtn() ? onPlayClicked : onPauseClicked}
          className={styles.playOverlayIcon}
          icon={shouldDisplayPlayBtn() ? 'icon-play-video' : 'icon-pause'}
          size={IconSize.SIZE_ICON_24}
          shape={IconShape.NONE}
          type={IconType.NONE}
        />
      </div>
    );
  };

  const shouldDisplayPlayBtn = (): boolean => {
    return (isHovered || isSmallScreen()) && !isPlaying;
  };

  const onPlayClicked = async (e: MouseEvent): Promise<void> => {
    e.stopPropagation();
    dispatch(playAudioGridItem(props.uid));
    if (audioRef && audioRef.current) {
      await audioRef.current.play();
    }
  };

  const onPauseClicked = (e: MouseEvent): void => {
    e.stopPropagation();
    dispatch(pauseAudioGridItem(props.uid));
    if (audioRef && audioRef.current) {
      audioRef.current.pause();
    }
  };

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const getDefaultState = (): ReactElement => {
    return (
      <>
        {getAudioItemGraphicContainer()}
        {getInfoContainer()}
        {getAudioPlayer()}
      </>
    );
  };

  const handleSeekTimeUpdate = (): void => {
    if (audioRef.current) {
      const currentSeekTime = audioRef.current.currentTime;
      setSeekTime(currentSeekTime);
    }
  };

  const onAudioEnded = (): void => {
    dispatch(pauseAudioGridItem(props.uid));
  };

  const getAudioPlayer = (): ReactElement => {
    return (
      <audio ref={audioRef} onTimeUpdate={handleSeekTimeUpdate} onEnded={onAudioEnded}>
        <source src={props.source} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    );
  };

  const getRenamingState = (): ReactElement => {
    const inputControls: InputFieldControls = {
      showDefaultControls: false,
      showSingleDefaultControlOnly: false,
      customControls: [
        {
          id: renamingCloseBtnID,
          type: InputControlItemType.ICON_BTNS,
          icon: 'icon-close',
        },
        {
          id: renamingDoneBtnID,
          type: InputControlItemType.ICON_BTNS,
          icon: 'icon-check',
        },
      ],
      onClick(iconName: string, e?: InputFieldControlItemEvent) {
        if (e) {
          e.stopPropagation();
        }
        if (iconName === renamingDoneBtnID) {
          onRenamingDone();
        } else if (iconName === renamingCloseBtnID) {
          onRenamingCancelled();
        } else {
          throw new Error('Unhandled button id in rename flow');
        }
        setIsRenaming(false);
      },
    };

    return <InputField input={title} className={styles.input} size={InputFieldSize.SMALL} onInputChange={onRenameFieldChanged} controls={inputControls} stopClickPropagation />;
  };

  const onRenamingDone = (): void => {
    if (!title.trim()) {
      setTitle(editedtitle.current);
      return;
    }
    editedtitle.current = title;
    if (props.onTitleRenamed) {
      void props.onTitleRenamed(title);
    }
  };

  const onRenamingCancelled = (): void => {
    setTitle(editedtitle.current);
  };

  const onRenameFieldChanged = (value: string): void => {
    setTitle(value);
  };

  const getClassesForContainer = (): string => {
    const itemSpacing = isRenaming ? 'flex-center' : 'flex-row-justify-between';
    const padding = isRenaming ? 'spacing-p-4' : '';
    return `${styles.audioItemContainer} flex-h-row border-s-standard radius-4 ${itemSpacing} ${padding}`;
  };

  useEffect(() => {
    if (audioRef && audioRef.current && !isPlaying) {
      audioRef.current.pause();
    }
  }, [audioGridItemPlaying]);

  useEffect(() => {
    return () => {
      if (isPlaying && audioRef && audioRef.current) {
        dispatch(pauseAudioGridItem(props.uid));
        audioRef.current.pause();
      }
    };
  }, []);

  return (
    <div
      key={props.uid}
      className={getClassesForContainer()}
      style={getStyleObj()}
      onMouseEnter={(): void => {
        setIsHovered(true);
      }}
      onMouseLeave={(): void => {
        setIsHovered(false);
      }}
    >
      {isRenaming ? getRenamingState() : getDefaultState()}
    </div>
  );
}
